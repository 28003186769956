import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/frontpage.png";

import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  // Decrease initial delta for faster typing effect
  const [delta, setDelta] = useState(200 - Math.random() * 50); // Adjust the range as per your need
  const [index, setIndex] = useState(1);
  const toRotate = [
    "Unparalleled Access to Contract Codes",
    "Comprehensive Security Analysis",
    "Revolutionizing Smart Contract Security",
  ];
  // Decrease period for less pause time
  const period = 1500; // Adjust as per your need

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      // Adjust the speed of deletion
      setDelta((prevDelta) => prevDelta / 2.5); // Made deletion slightly faster
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      // Reset delta to initial value for next round
      setDelta(100 - Math.random() * 50);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <a href="https://t.me/Guardians_AI_BOT" className="tagline">
                    Try The Bot
                  </a>

                  <h1 className="">{`Guardian AI`} </h1>
                  <span className="txt-rotate" dataPeriod="1000">
                    <span className="wrap smallerH1">{text}</span>
                  </span>

                  <p>
                    Our first telegram bot represents a groundbreaking
                    advancement in the realm of smart contract security. As a
                    sophisticated Telegram bot, Guardian AI stands out in the
                    field of contract scanning with its unparalleled
                    capabilities. What sets Guardian AI apart is its unique
                    access to the entirety of contract codes, a feature that is
                    not available in traditional scanners. This advanced AI
                    model dives deep into the complexities of smart contracts,
                    performing comprehensive checks that go beyond the
                    surface-level analysis offered by conventional scanners. By
                    accessing and analyzing the full contract code, Guardian AI
                    ensures a level of scrutiny and security that is unmatched
                    in the industry. With Guardian AI, users gain the confidence
                    of knowing that their contracts are not only checked for
                    common vulnerabilities but also scrutinized for deeper, more
                    intricate issues that might go unnoticed by other scanners.
                    This level of detail in contract analysis is what makes
                    Guardian AI an indispensable tool for anyone dealing with
                    smart contracts. Stay ahead in the game of smart contract
                    security with Guardian AI – your ultimate shield against
                    unseen vulnerabilities.
                  </p>
                  <a href="https://t.me/GuardianAIGroup" className="btn">
                    <button onClick={() => console.log("connect")}>
                      Join Telegram <ArrowRightCircle size={25} />
                    </button>{" "}
                  </a>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img src={headerImg} alt="Header Img" className="imgC" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
